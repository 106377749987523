import { Box, Button, Stack, Typography } from "@mui/joy";

import MainTemplate from "../../../templates/main";
import { useCallback, useEffect, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import "./_verification.scss";
import { Link, useParams } from "react-router-dom";

export default function VerifyAddressPage({ sender }: { sender: boolean }) {
  const { invoice } = useParams()

  const [success, setSuccess] = useState(false);
  const [customer, setCustomer] = useState<any>({})

  useEffect(() => {
    fetch(`https://hook.us1.make.com/727kjumi4ojipymps3k3f237ecwfcofq?inv=${invoice}`)
      .then(results => results.json())
      .then((data: any) => {
        setCustomer(data)
      })
  }, [invoice])

  const submitForm = useCallback(() => {
      setSuccess(true);
  }, [setSuccess]);


  return (
    <MainTemplate page="Verify" sender={sender}>
      <>
        <Box
          sx={{
            display: 'flex',
            mb: 1,
            gap: 1,
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'start', sm: 'center' },
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Typography level="h2" component="h1">
            Confirm Shipping Address
          </Typography>
        </Box>
        {!success ? <Stack height="400px" justifyContent="center" spacing={8} padding="18px">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography level="title-md">{customer.recipient_address}</Typography>
          </Stack>
          <Button onClick={submitForm}>Confirm</Button>
        </Stack> :
          <Stack height="60%" justifyContent="center" alignItems="center" spacing={2} padding="18px">
            <CheckCircleIcon color="success" style={{ width: 200, height: 200 }} />
            <Typography textAlign="center" level="h2" color="success">Success! Your address has been confirmed!</Typography>
            <Typography textAlign="center" level="h4">You can close this window</Typography>
            <Link to={`/recipient/order-status/${invoice}`}>Back to Order Status</Link>
          </Stack>
        }
      </>
    </MainTemplate>
  );
}