export const ROUTES = {
    login: "/",
    dashboard: "/sender/dashboard",
    create_order: "/sender/create-order",
    print_qr_code: "/sender/print-qr-code/:invoice",
    sender_order_status: "/sender/order-status/:invoice",
    sender_customer_service: "/sender/customer-service",
    recipient_order_status: "/recipient/order-status/:invoice",
    verify_receipt: "/recipient/verify/:invoice",
    verify_address: "/recipient/verify-address/:invoice",
    authorize_signer: "/recipient/authorize-signer/:invoice",
    remote_verify_receipt: "/recipient/remote-verify/:invoice",
    recipient_customer_service: "/recipient/customer-service",
    recipient_parcel_back: "/recipient/parcel-back/:invoice",
    scan_label: "/recipient/scan",
    driver_verify: "/driver/verify/:invoice",
    pick_pack: "/pick-pack/:invoice",
    parcel_chain: "/parcel-chain/:invoice",
}