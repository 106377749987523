import { Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { WebcamCapture } from "./camera";

export default function PickPackParcelPicturePage() {
  const { invoice } = useParams();
  const [image, setImage] = useState('');

  useEffect(() => {
    if (image) {
      fetch(`https://hook.us1.make.com/f361ing9quxbltzh8dqb1opawopt4ll1`, {
        method: 'POST',
        body: JSON.stringify({
          invoice_id: invoice,
          image: image
        } as any),
        headers: {
          'Content-Type': 'application/json'
        }
      });
    }
  }, [invoice, image]);


  return (
    <Stack height="60%" justifyContent="center" alignItems="center" spacing={2} padding="18px">
      {image && <Typography textAlign="center" level="h2" color="success">Item Captured!</Typography>}
      {image && <CheckCircleIcon color="success" style={{ width: 200, height: 200 }} />}
      <Typography textAlign="center" level="h4">{!image ? 'Please take a photo of the package' : <></>}</Typography>
      {!image ? <WebcamCapture setImage={setImage} /> : <Typography textAlign="center" level="h4">Thank You!</Typography>}
    </Stack>
  )
}