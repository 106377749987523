import { Button, Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MainTemplate from "../../../templates/main";
import { VideoRecordingCapture } from "./recording";


//@ts-ignore
import Lottie from 'react-lottie';
//@ts-ignore
import * as animationData from './animation.json'
import { ItemCapture } from "./itemcapture";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

export default function ParcelBackPage({ sender }: { sender: boolean }) {
  const { invoice } = useParams();
  const [clickedNext, setClickedNext] = useState(false);

  const [image, setImage] = useState('');
  const [imageVerified, setImageVerified] = useState(false);
  const [video, setVideo] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  const [error, setError] = useState(false);
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    if (image) {
      setImageLoading(true);
      fetch(`https://hook.us1.make.com/w7ouhgg9yfubg5gzq9qhfp191sab9tas`, {
        method: 'POST',
        body: JSON.stringify({
          invoice_id: invoice,
          image: image
        } as any),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => response.json()).then((results) => {
        console.log(results);
        if (!results.message.includes('Verified') || results.message.includes('Not Verified')) {
          setError(true);
          setImageVerified(false);
          setAttempts(a => a + 1);
          setImage('');
        } else {
          setError(false);
          setImageVerified(true);
        }
        setImageLoading(false);
      });
    }

  }, [invoice, image]);

  useEffect(() => {
    if (video) {
      const formData = new FormData();
      const invoice_id = invoice || '';
      formData.append('invoice_id', invoice_id);
      formData.append('video', video, 'parcel-back.webm');

      setVideoLoading(true);
      fetch(`https://hook.us1.make.com/8t75c5vmrfoqh1divx969xlhu5lzo30h`, {
        method: 'POST',
        body: formData
      }).then(() => {
        setVideoLoading(false);
      });
    }

  }, [invoice, video]);


  if (attempts > 1) {
    return (
      <MainTemplate page="Add Authorized Signer" sender={sender}>
        <Stack height="100%" justifyContent="center" alignItems="center" spacing={2} padding="18px">
          <Typography textAlign="center" level="h4">Please call Customer Service to process your return.</Typography>
        </Stack>
      </MainTemplate>
    );
  }

  if (!image || imageLoading || error) {
    return (
      <MainTemplate page="Add Authorized Signer" sender={sender}>
      <Stack height="100%" justifyContent="center" alignItems="center" spacing={2} padding="18px">
        <ItemCapture loading={imageLoading} hasError={error} image={image} setImage={setImage} />
      </Stack>
    </MainTemplate>
    )
  }

  if (!clickedNext) {
    return (
      <MainTemplate page="Add Authorized Signer" sender={sender}>
        <Stack height="100%" justifyContent="center" alignItems="center" spacing={2} padding="18px">
          <Typography textAlign="center" level="h4">Begin a video recording of packing the item in the box.</Typography>
          <img width="300" src="/IMG_4982.png" alt="propped up phone" />
          <Typography textAlign="center" level="body-md">Please prop up your phone against a surface and do not move it throughout the return process.</Typography>
          <Button sx={{ mt: 0 }} color="success" onClick={() => {
            setClickedNext(true);
          }} size="lg">Next</Button>
        </Stack>
      </MainTemplate>
    )
  }

  if (!video) {
    return (
      <MainTemplate page="Add Authorized Signer" sender={sender}>
        <Stack height="100%" justifyContent="center" alignItems="center" spacing={2} padding="18px">
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Lottie options={defaultOptions}
              style={{ margin: 0, overflow: 'visible' }}
              height={35}
              width={84} />
          </div>
          <VideoRecordingCapture loading={videoLoading} hasError={error} setVideo={setVideo} imageVerified={imageVerified} image={image} setImage={setImage} />
        </Stack>
      </MainTemplate>
    );
  }

  return (
    <MainTemplate page="Add Authorized Signer" sender={sender}>
      <Stack height="100%" justifyContent="center" alignItems="center" spacing={2} padding="18px">
        <Typography textAlign="center" level="h2" color="success">Return Process Complete!</Typography>
        <CheckCircleIcon color="success" style={{ width: 200, height: 200 }} />
        <Typography textAlign="center" level="body-md">Your return has been successfully completed.</Typography>
        <Typography textAlign="center" level="body-md">Thank you for using our remote returns process.</Typography>
      </Stack>
    </MainTemplate>
  );
}