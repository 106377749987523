import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";

import DashboardPage from './pages/sender/dashboard'
import JoySignInSideTemplate from "./pages/common/login";
// import JoyMessagesTemplate from "./pages/common/customer-service";

import './app.css'
import VerificationPage from "./pages/recipient/verification";
import OrderStatusPage from "./pages/common/order-status";
import ScannerPage from "./pages/recipient/scanner";
import NotFound from "./pages/common/not-found";
import { ROUTES } from "./constants";
import CreateOrder from "./pages/sender/create-order";
import PrintQRCode from "./pages/sender/print-qr-code";
import DriverVerificationPage from "./pages/driver/verify";
import RemoteVerificationPage from "./pages/recipient/remote-verification";
import PickPackParcelPicturePage from "./pages/pick-pack";
import ParcelChainVerificationPage from "./pages/parcel-chain";
import VerifyAddressPage from "./pages/recipient/verify-address";
import AuthorizeSignerPage from "./pages/recipient/authorize-signer";
import ParcelBackPage from "./pages/recipient/parcel-back";

const AuthenticatedRoute = ({ children } : { children: React.ReactNode }) => {
  if (localStorage.getItem("logged-in") !== process.env.REACT_APP_GET_PASS) {
    return <Navigate to="/" replace={true} />
  }
  return <>{children}</>
}


const router = createBrowserRouter([
  {
    path: ROUTES.login,
    element: <JoySignInSideTemplate />,
  },
  {
    path: ROUTES.dashboard,
    element: <AuthenticatedRoute><DashboardPage sender={true} /></AuthenticatedRoute>,
  },
  {
    path: ROUTES.create_order,
    element: <AuthenticatedRoute><CreateOrder sender={true} /></AuthenticatedRoute>
  },
  {
    path: ROUTES.print_qr_code,
    element: <AuthenticatedRoute><PrintQRCode sender={true} /></AuthenticatedRoute>
  },
  {
    path: ROUTES.sender_order_status,
    element: <AuthenticatedRoute><OrderStatusPage sender={true} /></AuthenticatedRoute>
  },
  // {
  //   path: ROUTES.sender_customer_service,
  //   element: <AuthenticatedRoute><JoyMessagesTemplate sender={true} /></AuthenticatedRoute>
  // },
  {
    path: ROUTES.recipient_order_status,
    element: <OrderStatusPage sender={false} />
  },
  {
    path: ROUTES.verify_receipt,
    element: <VerificationPage sender={false} />
  },
  {
    path: ROUTES.remote_verify_receipt,
    element: <RemoteVerificationPage sender={false} />
  },
  {
    path: ROUTES.verify_address,
    element: <VerifyAddressPage sender={false} />
  },
  {
    path: ROUTES.authorize_signer,
    element: <AuthorizeSignerPage sender={false} />
  },
  {
    path: ROUTES.recipient_parcel_back,
    element: <ParcelBackPage sender={false} />
  },
  // {
  //   path: ROUTES.recipient_customer_service,
  //   element: <JoyMessagesTemplate sender={false} />
  // },
  {
    path: ROUTES.scan_label,
    element: <ScannerPage />
  },
  {
    path: ROUTES.driver_verify,
    element: <DriverVerificationPage />
  },
  {
    path: ROUTES.pick_pack,
    element: <PickPackParcelPicturePage />
  },
  {
    path: ROUTES.parcel_chain,
    element: <ParcelChainVerificationPage />
  },
  {
    path: "*",
    element: <NotFound />
  }

]);

const App = () => {


  return (
    <RouterProvider router={router} />
  )
}

export default App 