import { Box, Button, FormControl, FormLabel, Input, Stack, Typography } from "@mui/joy";

import { useCallback, useEffect, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import "./_parcel-chain.scss";
import { useParams } from "react-router-dom";

export default function ParcelChainVerificationPage() {
  const { invoice } = useParams()

  const [success, setSuccess] = useState(false);
  const [coords, setCoords] = useState<any>({});
  const [temp, setTemp] = useState<any>('');

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCoords(position.coords)
      });
    }

  }, []);

  const submitForm = useCallback(() => {
    fetch(`https://hook.us1.make.com/65gf66bxw82o3eqbdclc2e52u9rj21a4`, {
      method: 'POST',
      body: JSON.stringify({
        invoice: invoice,
        lat: coords.latitude,
        long: coords.longitude,
        temp: temp
      } as any),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(results => results.json()).then(data => {
      setSuccess(data.success)
    })

  }, [invoice, coords, temp]);


  return (
    <Stack height="60%" justifyContent="center" alignItems="center" spacing={2} padding="18px">
      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'start', sm: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography level="h2" component="h1">
          Confirm Package Checkpoint
        </Typography>
      </Box>
      {!success ? <Stack height="400px" justifyContent="center" spacing={8} padding="18px" width="100%">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <FormControl sx={{ flexGrow: 1 }}>
            <FormLabel>Temperature (Farenheit)</FormLabel>
            <Input
              size="sm"
              type="number"
              placeholder="72"
              value={temp}
              onChange={(e) => setTemp(e.target.value)}
              sx={{ flexGrow: 1 }}
            />
          </FormControl>
        </Stack>
        <Button onClick={submitForm}>Confirm Checkpoint</Button>
      </Stack> :
        <Stack height="60%" justifyContent="center" alignItems="center" spacing={2} padding="18px">
          <CheckCircleIcon color="success" style={{ width: 200, height: 200 }} />
          <Typography textAlign="center" level="h2" color="success">Success! Thank you for Confirming!</Typography>
          <Typography textAlign="center" level="h4">You can close this window</Typography>
        </Stack>
      }
    </Stack>
  );
}