import { Box, Button, FormControl, FormHelperText, FormLabel, Input, Stack, Typography } from "@mui/joy";

import MainTemplate from "../../../templates/main";
import { useCallback, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import "./_verification.scss";
import { Link, useParams } from "react-router-dom";
import { InfoOutlined } from "@mui/icons-material";

export default function AuthorizeSignerPage({ sender }: { sender: boolean }) {
  const { invoice } = useParams()

  const [phoneError, setPhoneError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [authName, setAuthName] = useState('');
  const [authPhone, setAuthPhone] = useState('');

  const submitForm = useCallback(() => {
    const regex = /\d\d\d\d\d\d\d\d\d\d/

    if (!regex.test(authPhone)) {
      setPhoneError(true)
      return
    }

    if (authName.split(' ').length < 2) {
      setNameError(true);
      return;
    }

    fetch(`https://hook.us1.make.com/tore1cmyxxporxw9zhrb63nk7sd9ax18`, {
      method: 'POST',
      body: JSON.stringify({
        invoice: invoice,
        name: authName,
        phone: authPhone,
      } as any),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(results => results.json()).then(data => {
      setSuccess(data.success)
    })
  }, [invoice, authName, authPhone]);


  return (
    <MainTemplate page="Add Authorized Signer" sender={sender}>
      <>
        <Box
          sx={{
            display: 'flex',
            mb: 1,
            gap: 1,
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'start', sm: 'center' },
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Typography level="h2" component="h1">
            Add Authorized Signer
          </Typography>
        </Box>
        {!success ? <Stack height="400px" justifyContent="center" spacing={8} padding="18px">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <FormControl sx={{ flexGrow: 1 }}>
              <FormLabel>Authorized Name</FormLabel>
              <Input
                size="sm"
                type="text"
                placeholder="John Smith"
                value={authName}
                onChange={(e) => setAuthName(e.target.value)}
                sx={{ flexGrow: 1 }}
              />
              {nameError && <FormHelperText>
                <InfoOutlined />
                Must include first and last name
              </FormHelperText>}
            </FormControl>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <FormControl sx={{ flexGrow: 1 }}>
              <FormLabel>Authorized Phone Number</FormLabel>
              <Input
                size="sm"
                type="text"
                placeholder="5555555555"
                value={authPhone}
                onChange={(e) => setAuthPhone(e.target.value)}
                sx={{ flexGrow: 1 }}
              />
              {phoneError && <FormHelperText>
                <InfoOutlined />
                Please use 10 digit format with no other characters
              </FormHelperText>}
            </FormControl>
          </Stack>
          <Button onClick={submitForm}>Authorize Signer</Button>
        </Stack> :
          <Stack height="60%" justifyContent="center" alignItems="center" spacing={2} padding="18px">
            <CheckCircleIcon color="success" style={{ width: 200, height: 200 }} />
            <Typography textAlign="center" level="h2" color="success">Success! You have added {authName} as an authorized signer!</Typography>
            <Typography textAlign="center" level="h4">You can close this window</Typography>
            <Link to={`/recipient/order-status/${invoice}`}>Back to Order Status</Link>
          </Stack>
        }
      </>
    </MainTemplate>
  );
}