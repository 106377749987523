import { Button, CircularProgress, Stack, Typography } from "@mui/joy";
import React, { useState } from "react";
import Webcam from "react-webcam";

const videoConstraints = {
  facingMode: { exact: "user" },
  width: 150,
  height: 150,
};

const steps: Record<string, any> = {
  begin: {
    title: 'Adjust your phone for the return process',
    instructions: 'Please adjust your phone to ensure that all items and return materials are visible to the camera.'
  },
  pack: {
    title: 'Pack the Verified Item',
    instructions: 'Please hold the verified item and place it into the box.'
  },
  seal: {
    title: 'Seal the Box',
    instructions: 'Seal the box securely with the provided anti-tamper tape.'
  }
}

export const VideoRecordingCapture = ({ loading, hasError, setVideo, image, imageVerified, setImage }: { loading: boolean, hasError: boolean, image: any, imageVerified: boolean, setVideo: (i: any) => void, setImage: (i: any) => void }) => {
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [recordedChunks, setRecordedChunks] = React.useState([]);

  const [step, setStep] = useState<string>('begin');

  React.useEffect(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm"
      });

      setVideo(blob);
    }
  }, [recordedChunks, setVideo]);

  const handleDataAvailable = React.useCallback(
    // @ts-ignore
    ({ data }) => {
      if (data.size > 0) {
        console.log(data);
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleNext = React.useCallback(() => {
    switch (step) {
      case 'begin':
        // @ts-ignore
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
          mimeType: "video/mp4"
        });
        // @ts-ignore
        mediaRecorderRef.current.addEventListener(
          "dataavailable",
          handleDataAvailable
        );
        // @ts-ignore
        mediaRecorderRef.current.start();
        setStep('pack');
        return
      case 'pack':
        setStep('seal');
        return;
      case 'seal':
        // @ts-ignore
        mediaRecorderRef.current.stop();
        return;
    }
  }, [step, handleDataAvailable]);

  if (loading) {
    return (
      <Stack height="100%" position="absolute" top="0" justifyContent="center" alignItems="center" spacing={2} padding="18px">
        <Typography textAlign="center" level="h4">Please wait while we upload your remote return video.</Typography>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <Typography textAlign="center" level="h4">{steps[step].title}</Typography>
      {hasError && <Typography textAlign="center" level="h4">{steps[step].errorMessage}</Typography>}
      <Webcam width={350} audio={false} ref={webcamRef} videoConstraints={videoConstraints} screenshotFormat="image/jpeg" screenshotQuality={0.4} />
      <Typography textAlign="center" level="body-md">{steps[step].instructions}</Typography>
      {steps[step].sub_instructions && <Typography textAlign="center" level="body-md">{steps[step].sub_instructions}</Typography>}
      <Button sx={{ mt: 0 }} color="success" onClick={handleNext} size="lg">Next</Button>
    </>
  );
};