import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import { Button, ButtonGroup, Chip, ColorPaletteProp, Sheet, Table } from '@mui/joy';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BlockIcon from '@mui/icons-material/Block';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import { phoneNumberAutoFormat, statusToText } from '../../utils';
import { ROUTES } from '../../constants';

import Intercom from '@intercom/messenger-js-sdk';

export default function OrderStatus({ sender }: { sender: boolean }) {
  const navigate = useNavigate();

  const { invoice } = useParams()

  const [customer, setCustomer] = useState<any>({})

  const sku_rows: any = [
    {
      sku: 'SM21-SH-M02-RD-S-001',
      description: `${customer.item_description}`,
      qty: 1
    }
  ]

  if (!sender && customer.name) {
    Intercom({
      app_id: process.env.REACT_APP_INTERCOM_APP_ID || '',
      name: customer.name,
      email: customer.email,
      created_at: Date.now() / 1000
    });
  }

  useEffect(() => {
    fetch(`https://hook.us1.make.com/727kjumi4ojipymps3k3f237ecwfcofq?inv=${invoice}`)
      .then(results => results.json())
      .then((data: any) => {
        setCustomer(data)
      })
  }, [invoice])

  useEffect(() => {
    function initMap() {
      //@ts-ignore
      const map = new google.maps.Map(document.getElementById("map"), {
        zoom: 12,
        center: { lat: 25.8, lng: -80.2 },
      });

      //@ts-ignore
      new google.maps.Marker({ position: map.center, map: map })
    }

    //@ts-ignore
    window.initMap = initMap;

    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&callback=initMap&v=weekly`

    document.body.appendChild(script);

    return () => {
      //@ts-ignore
      window.initMap = null;
    }

  }, []);

  return (

    <Box
      component="main"
      sx={{
        // height: '100vh', // 55px is the height of the NavBar
        display: 'grid',
        gridTemplateColumns: { xs: 'auto', md: '60% auto' },
        gridTemplateRows: 'auto 1fr auto',
        columnGap: 5,
        rowGap: 5
      }}
    >
      <Stack spacing={2} sx={{ pt: 2, minHeight: 0 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <div>
            <Box
              sx={{
                display: 'flex',
                mb: 1,
                gap: 1,
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'start', sm: 'center' },
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <Typography level="h2" component="h1">
                Order Status
              </Typography>
              {/* <Button
              color="primary"
              startDecorator={<DownloadRoundedIcon />}
              size="sm"
            >
              Download PDF
            </Button> */}
            </Box>
            <Stack spacing={2} sx={{ overflow: 'auto' }}>
              <Typography level="h2">{invoice}</Typography>
            </Stack>
            <Typography level="title-md" style={{ color: "#000000", fontWeight: 600 }}>
              {customer.name}
            </Typography>
            <Typography style={{ marginTop: 0 }} component="div" color="neutral">
              {customer.email}
            </Typography>

            <Typography style={{ marginTop: 0 }} component="div" color="neutral">
              {phoneNumberAutoFormat(customer.phone)}
            </Typography>

            <Typography level="title-md" style={{ color: "#000000", fontWeight: 600 }}>
              Shipper's Address
            </Typography>
            <Typography style={{ marginTop: 0 }} component="div" color="neutral">
              {customer.sender_address}
            </Typography>

            <Typography level="title-md" style={{ color: "#000000", fontWeight: 600 }}>
              Recipient's Address
            </Typography>
            <Typography style={{ marginTop: 0 }} component="div" color="neutral">
              {customer.recipient_address}
            </Typography>

            <Typography level="title-md" style={{ color: "#000000", fontWeight: 600 }}>
              QR Code URL
            </Typography>

            <Typography style={{ marginTop: 0 }} component="div" color="neutral">
              <a href={`${customer.qr_code_url}`}>Link</a>
            </Typography>

            {customer.parcel_return_video && (
              <>
                <Typography level="title-md" style={{ color: "#000000", fontWeight: 600 }}>
                  ParcelBack Recording
                </Typography>

                <Typography style={{ marginTop: 0 }} component="div" color="neutral">
                  <a href={`${customer.parcel_return_video}`} target='_blank' rel="noreferrer">Video Link</a>
                </Typography>
              </>
            )}

            <Stack spacing={2} sx={{ py: 2 }} flexDirection={"row"} justifyContent={"space-between"} alignItems="center" maxWidth={"12rem"}>
              <Typography component="div" style={{ color: "#000000", fontWeight: 600 }}>
                Status:
              </Typography>
              <Chip
                variant="soft"
                size="sm"
                style={{ marginTop: 0 }}
                startDecorator={
                  {
                    'in_transit': <AccessTimeIcon />,
                    'out_for_delivery': <AccessTimeIcon />,
                    delivered: <LocalShippingIcon />,
                    Confirmed: <CheckRoundedIcon />,
                    'pre_transit': <AutorenewRoundedIcon />,
                    'failure': <BlockIcon />,
                    'return_to_sender': <BlockIcon />,
                    'unknown': <AutorenewRoundedIcon />
                  }[customer.status as string]
                }
                color={
                  {
                    'in_transit': 'warning',
                    'out_for_delivery': 'warning',
                    delivered: 'primary',
                    Confirmed: 'success',
                    'pre_transit': 'neutral',
                    'failure': 'danger',
                    'return_to_sender': 'danger',
                    'unknown': 'neutral'
                  }[customer.status as string] as ColorPaletteProp
                }
              >
                {statusToText(customer.status)}
              </Chip>
            </Stack>
          </div>
          {customer.parcel_picture_image && <div style={{ width: '16rem' }}>
            <Typography level="title-md" style={{ color: "#000000", fontWeight: 600 }}>
              ParcelPicture
            </Typography>
            <img src={customer.parcel_picture_image} alt="parcel" width="100%" />
            <Typography level="title-md" style={{ color: "#000000", fontWeight: 600 }}>
              AI Analysis
            </Typography>
            <Typography level="body-sm">{customer.parcel_picture_text}</Typography>
          </div>}
        </div>



        <Stack spacing={2} flexDirection={"column"} justifyContent="flex-start" alignItems="flex-start">
          <ButtonGroup spacing={1} variant="solid">
            {/* <Button
            color="danger"
            onClick={() => navigate(ROUTES.recipient_customer_service)}
            size="lg"
          >
            Customer Service
          </Button> */}
            <Button
              sx={{ mt: 0 }}
              color="success"
              onClick={() => navigate(ROUTES.scan_label)}
              size="lg"
            >
              Confirm Receipt
            </Button>
            <Button
              sx={{ mt: 0 }}
              color="warning"
              onClick={() => navigate(`/recipient/authorize-signer/${invoice}/`)}
              size="lg"
            >
              Add Authorized Signer
            </Button>
          </ButtonGroup>
          <ButtonGroup spacing={1} variant="solid">
            {/* <Button
            color="danger"
            onClick={() => navigate(ROUTES.recipient_customer_service)}
            size="lg"
          >
            Customer Service
          </Button> */}
            {customer.status === 'Confirmed' && <Button
              sx={{ mt: 0 }}
              color="primary"
              onClick={() => navigate(`/recipient/parcel-back/${invoice}/`)}
              size="lg"
            >
              ParcelBack Return
            </Button>}
          </ButtonGroup>
        </Stack>
        <Stack spacing={2} sx={{ pt: 2, display: { xs: 'none', sm: 'initial' } }}>
          <Typography level="title-md" style={{ color: "#000000", fontWeight: 600 }}>
            Shipment Contents
          </Typography>
          <Sheet
            className="OrderTableContainer"
            variant="plain"
            sx={{
              display: { xs: 'none', sm: 'initial' },
              width: '100%',
              borderRadius: 'sm',
              flexShrink: 1,
              overflow: 'auto',
              minHeight: 0,
            }}
          >
            <Table
              aria-labelledby="tableTitle"
              stickyHeader
              hoverRow
              sx={{
                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                '--Table-headerUnderlineThickness': '1px',
                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                '--TableCell-paddingY': '4px',
                '--TableCell-paddingX': '8px',
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: 140, padding: '12px 6px' }}>SKU</th>
                  <th style={{ width: 280, padding: '12px 6px' }}>Description</th>
                  <th style={{ width: 140, padding: '12px 6px' }}>Qty</th>
                </tr>
              </thead>
              <tbody>
                {sku_rows.map((row: any) => (
                  <tr key={row.id}>
                    <td>
                      <Typography level="body-xs">{row.sku}</Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">{row.description}</Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">{row.qty}</Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        </Stack>

        <Stack spacing={2} sx={{ pt: 2, display: { xs: 'initial' } }}>
          <Typography level="title-md" style={{ color: "#000000", fontWeight: 600 }}>
            Shipment History
          </Typography>
          <Sheet
            className="OrderTableContainer"
            variant="plain"
            sx={{
              display: { xs: 'initial' },
              width: '100%',
              borderRadius: 'sm',
              flexShrink: 1,
              overflow: 'auto',
            }}
          >
            <Table
              aria-labelledby="tableTitle"
              stickyHeader
              hoverRow
              sx={{
                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                '--Table-headerUnderlineThickness': '1px',
                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                '--TableCell-paddingY': '4px',
                '--TableCell-paddingX': '8px',
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: 140, padding: '12px 6px' }}>Timestamp</th>
                  <th style={{ width: 140, padding: '12px 6px' }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {customer.tracking_details?.reverse().map((row: any) => (
                  <tr key={row.id}>
                    <td>
                      <Typography level="body-xs">{(new Date(row.datetime)).toLocaleString()}</Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">{row.message}{row.tracking_location.city ? ' - ' : ''}{row.tracking_location.city}{row.tracking_location.city ? ', ' : ''}{row.tracking_location.state}</Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        </Stack>

        {customer.parcel_chain && customer.parcel_chain[0]?.lat !== null && <Stack spacing={2} sx={{ pt: 2, display: { xs: 'initial' } }}>
          <Typography level="title-md" style={{ color: "#000000", fontWeight: 600 }}>
            ParcelChain History
          </Typography>
          <Sheet
            className="OrderTableContainer"
            variant="plain"
            sx={{
              display: { xs: 'initial' },
              width: '100%',
              borderRadius: 'sm',
              flexShrink: 1,
              overflow: 'auto',
            }}
          >
            <Table
              aria-labelledby="tableTitle"
              stickyHeader
              hoverRow
              sx={{
                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                '--Table-headerUnderlineThickness': '1px',
                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                '--TableCell-paddingY': '4px',
                '--TableCell-paddingX': '8px',
              }}
            >
              <thead>
                <tr>
                  <th style={{ width: 140, padding: '12px 6px' }}>Location (Lat / Long)</th>
                  <th style={{ width: 140, padding: '12px 6px' }}>Time</th>
                  <th style={{ width: 140, padding: '12px 6px' }}>Temp</th>
                  <th style={{ width: 140, padding: '12px 6px' }}>Username</th>
                </tr>
              </thead>
              <tbody>
                {customer.parcel_chain?.reverse().map((row: any) => (
                  <tr key={row.id}>
                    <td>
                      <Typography level="body-xs">{`${row.lat}, ${row.long}`}</Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">{`${(new Date(row.time)).toLocaleString()}`}</Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">{`${row.temp}°F`}</Typography>
                    </td>
                    <td>
                      <Typography level="body-xs">{`${row.username}`}</Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        </Stack>}
        {customer.driver_lat && (
          <Stack spacing={2} sx={{ pt: 2, display: { xs: 'initial' } }}>
            <Typography level="title-md" style={{ color: "#000000", fontWeight: 600 }}>
              Driver Verification
            </Typography>
            <Sheet>

              <>
                <Typography level="title-md" style={{ color: "#000000", fontWeight: 600 }}>
                  Driver Location
                </Typography>

                <Typography level="body-md">{`${customer.driver_lat}, ${customer.driver_long}`}</Typography>
                <Typography level="title-md" style={{ color: "#000000", fontWeight: 600 }}>
                  Driver Verified Time
                </Typography>
                <Typography level="body-md">{`${(new Date(customer.driver_verify_time)).toLocaleString()}`}</Typography>
                <Typography level="title-md" style={{ color: "#000000", fontWeight: 600 }}>
                  Driver Verified Image
                </Typography>
                <img src={customer.driver_image} alt="driver" />
              </>
            </Sheet>
          </Stack>
        )}
      </Stack>
      <Stack spacing={2} sx={{ pt: 2, minHeight: 0 }}>
        <Box
          sx={{
            display: 'flex',
            mb: 1,
            gap: 3,
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'start', sm: 'center' },
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >

          <div style={{ display: window.innerWidth < 600 ? 'none' : 'block', minHeight: 800, minWidth: "100%" }} id="map" />
        </Box>
      </Stack>
    </Box>
  );
}
