import { Box, Card, Divider, Stack, Typography } from "@mui/joy"
import MainTemplate from "../../../templates/main"
import { useParams, useSearchParams } from "react-router-dom"
import QRCode from "react-qr-code";

const PrintQRCode = ({ sender }: { sender: boolean }) => {
  const { invoice } = useParams();

  const [searchParams,] = useSearchParams();
  const verification_type = searchParams.get("verification_type");
  const parcel_picture = searchParams.get("parcel_picture");
  const parcel_chain = searchParams.get("parcel_chain");

  return (
    <MainTemplate page="Print QR Code" activeSidebar='orders' sender={true}>
      <>
        <Box
          sx={{
            display: 'flex',
            mb: 1,
            gap: 1,
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'start', sm: 'center' },
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Typography level="h2" component="h1">
            Print QR Code
          </Typography>
        </Box>

        <Stack
          spacing={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '400px',
            // mx: 'auto',
            px: { xs: 2, md: 6 },
            py: { xs: 2, md: 3 },
          }}
        >
          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">{invoice} - Recipient</Typography>
            </Box>
            <Divider />
            <Stack
              direction="row"
              spacing={3}
              sx={{ display: { xs: 'flex' }, my: 1 }}
            >
              <QRCode value={`https://${process.env.REACT_APP_WEB_DOMAIN}/recipient/verify/${invoice}?verification_type=${verification_type}`} />
            </Stack>

          </Card>
          {parcel_picture === 'true' &&
            <Card>
              <Box sx={{ mb: 1 }}>
                <Typography level="title-md">{invoice} - ParcelPicture </Typography>
              </Box>
              <Divider />
              <Stack
                direction="row"
                spacing={3}
                sx={{ display: { xs: 'flex' }, my: 1 }}
              >
                <QRCode value={`https://${process.env.REACT_APP_WEB_DOMAIN}/pick-pack/${invoice}`} />
              </Stack>
            </Card>}

            {parcel_chain === 'true' &&
            <Card>
              <Box sx={{ mb: 1 }}>
                <Typography level="title-md">{invoice} - ParcelChain </Typography>
              </Box>
              <Divider />
              <Stack
                direction="row"
                spacing={3}
                sx={{ display: { xs: 'flex' }, my: 1 }}
              >
                <QRCode value={`https://${process.env.REACT_APP_WEB_DOMAIN}/parcel-chain/${invoice}`} />
              </Stack>
            </Card>}

          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">{invoice} - Driver</Typography>
            </Box>
            <Divider />
            <Stack
              direction="row"
              spacing={3}
              sx={{ display: { xs: 'flex' }, my: 1 }}
            >
              <QRCode value={`https://${process.env.REACT_APP_WEB_DOMAIN}/driver/verify/${invoice}`} />
            </Stack>

          </Card>

        </Stack>
      </>
    </MainTemplate>
  )
}

export default PrintQRCode