import { Button, CircularProgress, Stack, Typography } from "@mui/joy";
import React, { useState } from "react";
import Webcam from "react-webcam";

const videoConstraints = {
  facingMode: { exact: "environment" },
  width: 430,
  height: 340,
};

const steps: Record<string, any> = {
  begin: {
    title: "Let's begin the remote return process.",
    instructions: 'Please follow the instructions on screen.'
  },
  verify: {
    title: 'Verify Your Return Item',
    instructions: 'Please capture the item you wish to return to verify it.',
    sub_instructions: 'Once verified, wait for confirmation to proceed.',
    errorMessage: 'Item did not verify, please retake a photo.'
  }
}

export const ItemCapture = ({ loading, hasError, image, setImage }: { loading: boolean, hasError: boolean, image: any, setImage: (i: any) => void }) => {
  const webcamRef = React.useRef(null);

  const [step, setStep] = useState<string>(image ? 'verify' : 'begin');

  const handleNext = React.useCallback(() => {
    switch (step) {
      case 'begin':
        return setStep('verify');
      case 'verify':
        const imageSrc = (webcamRef.current as any).getScreenshot();
        setImage(imageSrc);
        return;
    }
  }, [step, setImage]);

  if (loading) {
    return (
      <Stack height="100%" position="absolute" top="0" justifyContent="center" alignItems="center" spacing={2} padding="18px">
        <Typography textAlign="center" level="h4">Please wait while we verify your item</Typography>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <Typography textAlign="center" level="h4">{steps[step].title}</Typography>
      {hasError && <Typography textAlign="center" level="h4">{steps[step].errorMessage}</Typography>}
      {step === 'begin' && <img src="/delivery-status.png" width="200" alt="return package" />}
      {step === 'verify' && <Webcam width={350} audio={false} ref={webcamRef} videoConstraints={videoConstraints} screenshotFormat="image/jpeg" screenshotQuality={0.4} />}
      <Typography textAlign="center" level="body-md">{steps[step].instructions}</Typography>
      {steps[step].sub_instructions && <Typography textAlign="center" level="body-md">{steps[step].sub_instructions}</Typography>}
      <Button sx={{ mt: 0 }} color="success" onClick={handleNext} size="lg">Next</Button>
    </>
  );
};